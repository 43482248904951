import changeProfileIcon from "../../assets/icons/changeProfileIcon.svg";
import { setUserProfile, getLocalToken, getLocalRefreshToken } from '../../services/localStorage';
import { Alert } from '../../components/Alert';
import { useNavigate } from 'react-router-dom';
import { setUserData } from '../../utils/setUserData';

interface ProfileItemProps {
 profile?: string;
 school?: string;
 id: number;
 handleClose: () => void;
}

export function ProfileItem({profile, school, id, handleClose}: ProfileItemProps) {
 const navigate = useNavigate()

 function capitalizeWords(str: string) {
   return str.replace(/\w\S*/g, (txt) => {
     return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
   })
 }

 function changeProfile() {
   const token = getLocalToken()
   const refreshToken = getLocalRefreshToken()
   
   setUserProfile(id)
   setUserData()
   Alert({
     type: 'success',
     message: "Perfil selecionado com sucesso.",
     autoClose: true,
   })
   setTimeout(() => {
     window.location.href = `${process.env.REACT_APP_LINK_TO_SAP}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${id}`
   }, 400)
   handleClose()
 }

 return(
   <div className={"flex items-center p-4 rounded border border-neutral-100 mt-4 sm:h-20 text-[#252833] justify-between gap-2"}>
     <div className='flex'>
       <img src={changeProfileIcon} alt="" />
       <div className="flex flex-col gap-2 justify-center ml-4">
         <strong className="text-sm font-semibold">{profile || ''}</strong>
         {school && <span className="text-sm">{capitalizeWords(school)}</span>}
       </div>
     </div>
     <div>
       <button onClick={changeProfile} className="w-max text-sm text-brand-primary-400 font-bold border border-[#DBDDE5] px-4 py-3">
         Utilizar perfil
       </button>
     </div>
   </div>
 )
}