import { useEffect, useState } from 'react'

import { Dropdown } from '../components/Dropdown'
import { MainHeader } from '../components/Header'
import HomeCard from '../components/HomeCard'

import { 
  getLocalRefreshToken,
  getLocalToken,
  getLocalUserData,
  getUserProfile,
  setUserProfile
} from '../services/localStorage'

import { Folder, Groups } from '@mui/icons-material'
import { getIsGuest } from '../api/user'
import { Footer } from '../components/Footer'
import { listProfiles } from '../api/profile'
import { Loader } from '../components/Loader'
import { useNavigate } from 'react-router-dom'
import { setUserData } from '../utils/setUserData'

export function Home() {
  const token = getLocalToken()
  const refreshToken = getLocalRefreshToken()
  const userProfile = getUserProfile()
  const userData = getLocalUserData()

  const [name, setName] = useState('')
  const [resources, setResources] = useState([''])

  const [isComunidade, setIsComunidade] = useState(false)
  const [isSAP, setIsSAP] = useState(false)
  const [isAPA, setIsAPA] = useState(false)
  const [isAva, setIsAva] = useState(false)
  const [isAdministrativo, setIsAdministrativo] = useState(false)
  const [isRepositorio, setIsRepositorio] = useState(false)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const canRegisterUser = resources.includes('72')
  const canAcessSchoolsAndClasses = resources.some((currentValue, index, arr) => {
    return currentValue === '60' || currentValue === '61' || currentValue === '62' ||
    currentValue === '63' || currentValue === '64' || currentValue === '65' ||
    currentValue === '66' || currentValue === '67' || currentValue === '68' ||
    currentValue === '69' || currentValue === '70' || currentValue === '71'
  })
  const isGestao = canRegisterUser || canAcessSchoolsAndClasses
  const isProgramas = isSAP || isAPA || isComunidade || isRepositorio || isAva || isAdministrativo
  
  useEffect(() => {
    window.location.href = 'https://spte-dev.nees.ufal.br/sap'
    
    document.title = 'Plataforma Integrada'

    if(getUserProfile() === '' || getUserProfile() === "undefined") {
      listProfiles().then((res)=> {
        setUserProfile(res.results[0].ids[0])
        setUserData()
        
        res.results.forEach((profile: any) => {
          if(profile.perfil === "Pre-cadastro") {
            setUserProfile(profile.ids[0])
            setUserData()
          }
        })

        return window.location.reload()
      })
    }
    
    userData.modulos.forEach((modulo: string) => {
      if(modulo === 'Sap'){
        setIsSAP(true)
      }else if(modulo === 'APA-EF'){
        setIsAPA(true)
      } else if(modulo === 'Comunidade de Práticas'){
        setIsComunidade(true)
      } else if(modulo === 'Repositório RBEH'){
        setIsRepositorio(true)
      } else if(modulo === 'Sistema Administrativo RBEH'){
        setIsAdministrativo(true)
      } else if(modulo === 'Ambiente Virtual de Aprendizagem RBEH'){
        setIsAva(true)
      }
    })

    let allResources: any = []
    userData.dados.forEach((profile : any) => {
      allResources += profile.recursos.map((resource : any) => {
        return resource
      })
    })

    setResources(allResources.toString().split(','))
    setName(userData.nome_usuario)
  },[])

  useEffect(() => {
    let isGuest = false
    
    userData.perfil.forEach((profile: any, index: number) => {
      if(!isGuest && profile.descricao_perfil === "Pre-cadastro"){
        isGuest = true
      }
    })

    getIsGuest(userData.cpf).catch((err) => {
      err.response.status === 404 && isGuest ? navigate('/pre-register') : setLoading(false)
    })

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <div id="home-container" className='w-[100%] h-full md:p-[0px] md:w-auto flex flex-col'>
      <MainHeader subtitles={['Página inicial']} />
      <div id="dropdown-container" className='mobile:flex justify-end tablet:hidden py-2 justify-content-end mx-8'>
        <Dropdown username={name} />
      </div>
      <div id="home-header-container" className="bg-center w-auto bg-[url('/src/assets/images/loginBanner.svg')] bg-no-repeat bg-cover resize flex items-center">
        <div id="home-header" className='flex flex-col py-12 ml-8 lg:py-[86px] lg:ml-[6.75rem]'>
          <h1 id="home-header-title" className='text-card leading-none text-white font-medium md:text-[52px]'>
            Bem-vindo(a) de volta.
          </h1>
          <p id="home-header-sentence" className='text-sm text-white leading-none mt-4 lg:mt-5 lg:text-xl1 lg:leading-[140%]'>
            Sistema de Proteção das Trajetórias Escolares
          </p>
        </div>
      </div>

      {/* GESTÃO */}

      {isGestao && 
        <div id="home-cards-container" className='w-[100%] px-[20px] sm:px-0 flex flex-col justify-center bg-[#f2f5fd]'>
          <section id="home-management-section" className='table mx-[auto] my-12 lg:max-w-[1200px]'>
            {isGestao && 
            <h1 id="home-management-title" className='text-2xl font-medium lg:text-card flex-1 mb-[20px]'>
              Gestão
            </h1>
            }
            
              <div id="home-management-cards" className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[24px] md:justify-start'>
              {canRegisterUser && (
                <HomeCard
                  title='Usuários'
                  id="cardhome-component-1"
                  headerIcon={
                    <Groups
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  }
                  text='Gerencie as contas de usuário cadastradas, altere
                        permissões, entre outros.'
                  path='/management'
                  params={{ state: { name } }}
                />
              )}

              {canAcessSchoolsAndClasses && (
                <HomeCard
                  title='Escolas, Turmas e Alunos'
                  id="cardhome-component-2"
                  headerIcon={
                    <Folder
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  }
                  text='Gerencie as escolas, turmas e os alunos a elas vinculados.'
                  path='/escolas'
                />
              )}
            </div>
          </section>
        </div>
      }

      {/* PROGRAMAS */}

      {isProgramas &&
        <div id="home-programs-container" className='w-[100%] px-[20px] sm:px-0 flex flex-col justify-center bg-white'>

          <section id="home-programs-section" className='table mx-[auto] my-12 max-w-[1200px]'>

            <h1 id="home-programs-title" className='text-2xl font-medium lg:text-card flex-1  mb-[20px]'>
              Programas
            </h1>
            <div id="home-programs-cards" className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[24px]'>
              {isAPA && 
                <HomeCard
                  title='Acompanhamento Personalizado da Aprendizagem (EM)'
                  id="cardhome-component-7"
                  headerIcon={
                    <Folder
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  }
                  text=''
                  path={`${process.env.REACT_APP_LINK_TO_APA}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
                  external={true}
                />
              }
              
              {isSAP &&
              <HomeCard
                title='Sistema de Proteção das Trajetórias Escolares (SPTe)'
                id="cardhome-component-8"
                headerIcon={
                  <Folder
                    sx={{
                      fontSize: '40px',
                    }}
                  />
                }
                text=''
                path={`${process.env.REACT_APP_LINK_TO_SAP}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
                external={true}
              />
              }
              
              {isComunidade &&
              <HomeCard
                title='Comunidade de Práticas'
                id="cardhome-component-9"
                headerIcon={
                  <Folder
                    sx={{
                      fontSize: '40px',
                    }}
                  />
                }
                text=''
                path={`${process.env.REACT_APP_LINK_TO_COMUNIDADE}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
                external={true}
              />
              }

              {isRepositorio &&
                <HomeCard
                  title='Repositório RIEH'
                  id="cardhome-component-9"
                  headerIcon={
                    <Folder
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  }
                  text=''
                  path={`${process.env.REACT_APP_LINK_TO_REPOSITORIO}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
                  external={true}
                />
              }

              {isAdministrativo &&
                <HomeCard
                  title='Sistema Administrativo RIEH'
                  id="cardhome-component-9"
                  headerIcon={
                    <Folder
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  }
                  text=''
                  path={`${process.env.REACT_APP_LINK_TO_SISTEMA_ADMINISTRATIVO}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
                  external={true}
                />
              }
              
              {isAva &&
                <HomeCard
                  title='Ambiente Virtual de Aprendizagem RIEH'
                  id="cardhome-component-9"
                  headerIcon={
                    <Folder
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  }
                  text=''
                  path={`${process.env.REACT_APP_LINK_TO_AVA}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
                  external={true}
                />
              }

            </div>
          </section>
        </div>
      }
      <Footer />
      <Loader loading={loading} />
    </div>
  )
}
