import okProfile from '../../assets/icons/okProfile.svg';
import changeProfileIcon from "../../assets/icons/changeProfileIcon.svg";
import { getUserProfile, setUserProfile } from '../../services/localStorage';
import { Alert } from '../Alert';
import { setUserData } from '../../utils/setUserData';
import { useNavigate } from 'react-router-dom';

interface ProfileItemProps {
  profile?: string;
  school?: string;
  id: number;
  handleClose: () => void;
}

/* className={classNames(
  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
  'w-full text-left py-2 text-sm rounded flex flex-row justify-start items-center cursor-pointer'
)} */

export function ProfileItem({profile, school, id, handleClose}: ProfileItemProps) {
  const selected: boolean = getUserProfile() === id?.toString() && school === localStorage.getItem('escola');
  const navigate = useNavigate()
  function capitalizeWords(str: string) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    })
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  function changeProfile() {
    setUserProfile(id)
    setUserData()
    localStorage.setItem('escola', school || '')
    
    Alert({
      type: 'success',
      message: "Perfil selecionado com sucesso.",
      autoClose: true,
    })
    
    setTimeout(() => {
      window.location.reload()
    }, 350)
    
    handleClose()
    
    navigate('/')
  }

  return(
    <div className={classNames(
      selected ? "bg-success" : "",
      "flex items-center p-4 rounded border border-neutral-100 mt-4 sm:h-20 text-[#252833] justify-between gap-2"
    )}>
      <div className='flex'>
        {
          selected ? (
            <img src={okProfile} alt="" />
          ) : (
            <img src={changeProfileIcon} alt="" />
          )
        }
        <div className="flex flex-col gap-2 justify-center ml-4">
          <strong className="text-sm font-semibold">{profile || ''}</strong>
          {school && <span className="text-sm">{capitalizeWords(school)}</span>}
        </div>
      </div>
      <div>
        {
          selected ? (
            <span className="text-[12px] text-[#217B1E]">Você está utilizando este perfil.</span>
          ) : (
          <button onClick={changeProfile} className="w-max text-sm text-brand-primary-400 font-bold border border-[#DBDDE5] px-4 py-3">
            Utilizar perfil
          </button>
          )
        }
      </div>
    </div>
  )
}