import { useEffect } from "react";
import { removeLocalToken } from "../services/localStorage";

export function Logout() {
  useEffect(() => {
    // Remove o token do localStorage
    removeLocalToken();
    
    // Redireciona para a nova URL
    window.location.href = "https://spte-dev.nees.ufal.br";
  }, []);

  return null;
}