import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from '@mui/material'
import { listProfiles } from "../../api/profile";
import { ProfileItem } from "./ProfileItem";
import { setUserData } from "../../utils/setUserData";

interface listProfilesProps{
  escolas: string[];
  ids: number[];
  perfil: string;
}

export function SelectProfile() {
  const [profiles, setProfiles] = useState<listProfilesProps[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    listProfiles().then((res) => {
      setProfiles(res.results)
      setUserData()
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    document.title = "Selecione seu perfil";
  }, [])

  return (
    <Box sx={{
      backgroundColor: '#002577',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
      <Box sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '32rem',
        padding: '2rem',
        borderRadius: '0.5rem',
      }}>
        <Typography fontSize="20px" color="#002577" fontWeight="700">Selecione seu perfil</Typography>
        <Box
          sx={{
            overflowY: 'scroll',
            maxHeight: '30rem',
          }}
        >
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress sx={{ color: '#002577' }} />
            </Box>
          ) : (
            profiles.map((profile: listProfilesProps) => {
              return (
                profile.escolas?.map((school: string, index: number) => {
                  return(
                    <ProfileItem
                      key={profile.ids[index]}
                      profile={profile.perfil}
                      school={school}
                      id={profile.ids[index]}
                      handleClose={() => {localStorage.setItem('escola', school)}}
                    />
                  )
                })
              )
            })
          )}
        </Box>
      </Box>
    </Box>
  )
}