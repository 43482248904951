import styled from "@emotion/styled"

import Box from "@mui/material/Box"

import neesImg from "../assets/images/nees.svg"
import ministerioImg from "../assets/images/ministerio.svg"
import { Typography } from "@mui/material"
import { FooterLink } from "./FooterLink"
import { getLocalRefreshToken, getLocalToken, getLocalUserData, getUserProfile, setUserProfile } from "../services/localStorage"
import { useEffect, useState } from "react"
import { listProfiles } from "../api/profile"

export function Footer () {
  const token = getLocalToken()
  const refreshToken = getLocalRefreshToken()
  const userProfile = getUserProfile()
  const userData = getLocalUserData()

  const [resources, setResources] = useState([''])

  const [isComunidade, setIsComunidade] = useState(false)
  const [isSAP, setIsSAP] = useState(false)
  const [isAPA, setIsAPA] = useState(false)
  const [isRepositorio, setIsRepositorio] = useState(false)
  const [isAdministrativo, setIsAdministrativo] = useState(false)
  const [isAva, setIsAva] = useState(false)

  const canRegisterUser = resources.includes('72')
  const canAcessSchoolsAndClasses = resources.some((currentValue, index, arr) => {
    return currentValue === '60' || currentValue === '61' || currentValue === '62' ||
    currentValue === '63' || currentValue === '64' || currentValue === '65' ||
    currentValue === '66' || currentValue === '67' || currentValue === '68' ||
    currentValue === '69' || currentValue === '70' || currentValue === '71'
  })
  const isGestao = canRegisterUser || canAcessSchoolsAndClasses
  const isProgramas = isSAP || isAPA || isComunidade || isRepositorio

  const Footer = styled('footer')({
    backgroundColor: '#013090',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '64px 108px 16px',
    color: '#F7F8FA',
  })

  const FooterTitle = styled('h1')({
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '170%',
    color: '#fff',
  })

  const FooterImage = styled('img')({
    height: '64px',
  })

  const FooterListBox =  styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    lineHeight: '150%',
    'h2': {
      fontWeight: 600,
      fontSize: '16px',
      cursor: 'pointer',
    },
    'p': {
      fontWeight: 400,
      fontSize: '14px',
      cursor: 'pointer',
    },
  })
  
  useEffect(() => {
    document.title = 'Plataforma Integrada'

    if(getUserProfile() === '' || getUserProfile() === "undefined") {
      listProfiles().then((res)=> {
        setUserProfile(res.results[0].ids[0])
        
        res.results.forEach((profile: any) => {
          if(profile.perfil === "Pre-cadastro") {
            setUserProfile(profile.ids[0])
          }
        })

        return window.location.reload()
      })
    }
    
    userData.modulos.forEach((modulo: string) => {
      if(modulo === 'Sap'){
        setIsSAP(true)
      }else if(modulo === 'APA-EF'){
        setIsAPA(true)
      } else if(modulo === 'Comunidade de Práticas'){
        setIsComunidade(true)
      } else if(modulo === 'Repositório RBEH'){
        setIsRepositorio(true)
      } else if(modulo === 'Sistema Administrativo RBEH'){
        setIsAdministrativo(true)
      } else if(modulo === 'Ambiente Virtual de Aprendizagem RBEH'){
        setIsAva(true)
      }
    })

    let allResources: any = []
    userData.dados.forEach((profile : any) => {
      allResources += profile.recursos.map((resource : any) => {
        return resource
      })
    })

    setResources(allResources.toString().split(','))
  },[userData])

  return (
    <Footer>
      <Box sx={{display: 'flex'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <FooterTitle> Plataforma de Recuperação da Aprendizagem e Redução do Abandono </FooterTitle>
          <Box sx={{ display: 'flex', gap: "32px" }}>
            <FooterImage src={ministerioImg} alt="Logo do Ministério da educação" />
            <FooterImage src={neesImg} alt="Logo do NEES" />
          </Box>
        </Box>
        <FooterListBox sx={{ marginLeft: '230px' }}>
          { isGestao ? (<Typography is="h2" component="h2">Gestão</Typography>) : null}
          {canRegisterUser ? (<FooterLink title="Gerenciamento de Usuários" path="/management"/>) : null}
          {canAcessSchoolsAndClasses ? (<FooterLink title="Gerenciamento de Escolas e Turmas" path="/schools"/>) : null}
        </FooterListBox>
        <FooterListBox sx={{ marginLeft: '64px' }} >
          { isProgramas ? (<Typography is="h2" component="h2">Programas</Typography>) : null}

          {isComunidade ? (
            <FooterLink
              title="Comunidade de Práticas" 
              path={`${process.env.REACT_APP_LINK_TO_COMUNIDADE}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
              external={true}
            />
          ): null}

          {isSAP ? (
            <FooterLink 
              title="Sistema de Proteção das Trajetórias Escolares (SPTe)"
              path={`${process.env.REACT_APP_LINK_TO_SAP}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
              external={true}
             />
          ): null}

          {isRepositorio ? (
            <FooterLink
              title="Repositório RBEH"
              path={`${process.env.REACT_APP_LINK_TO_REPOSITORIO}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
              external={true}
            />
          ) : null}

          {isAdministrativo ? (
            <FooterLink
              title="Sistema Administrativo RBEH"
              path={`${process.env.REACT_APP_LINK_TO_SISTEMA_ADMINISTRATIVO}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
              external={true}
            />
          ) : null}

          {isAva ? (
            <FooterLink
              title="Ambiente Virtual de Aprendizagem RBEH"
              path={`${process.env.REACT_APP_LINK_TO_AVA}?accessToken=${token}&refreshToken=${refreshToken}&userProfile=${userProfile}`}
              external={true}
            />
          ) : null}
        </FooterListBox>
      </Box>
      <Box 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '48px',
          fontSize: '12px',
          lineHeight: '133%',
        }}
      >
        <Typography 
          is="p"
          component="p"
        >
          Texto destinado a exibição das informações relacionadas à <span style={{ fontWeight: 'bolder' }}>licença de uso.</span>
        </Typography>
        <Typography is="p" component="p">
          <span className='font-bold'>Contato:</span> contato.plataformaintegrada@nees.ufal.br
        </Typography >
        {process.env.REACT_APP_MODE === "development" ? (
          <Typography is="p" component="p">
            <span className='font-bold'>Versão:</span> {process.env.REACT_APP_VERSION}
          </Typography>
        ) : null}
      </Box>
      
    </Footer>
  )
}